<template>
	<div class="specialList">
		<div class="crumbs">
			<span class="current">当前位置：</span>
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item><span class="cu" @click="toHome">首页</span></el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/courseList' }">专题列表</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="optionList">
			<div class="optionItem industry" :style="{'height':isOpen ? 'auto':'55px'}">
				<div ref="industryList">
					<span class="option_Tit">行业：</span>
					<span :class="{active:industryAll}" @click="choseIndustry('all')">全部</span>
					<span v-for="(item,index) in industryList" :key="index" @click="choseIndustry(item)" :class="{active: industryChose == item.dic_id}">{{item.dic_name}}</span>
				</div>
				<p class="moreIndustry" @click="openOrHide" v-if="moreShow">更多<i :class="isOpen?'el-icon-arrow-up':'el-icon-arrow-down'"></i></p>
			</div>
		</div>
		<div v-if="total">
			<div class="special_List">
				<div class="specialItem" v-for="(item,index) in specialList" :key="index">
					<div class="specialbg"></div>
					<img class="specialImg" :src="item.zt_thumb" @click="getSpecialDe(item)"/>
					<div class="specialTit">{{item.zt_name}}</div>
					<div class="specialCourses">
						<span>共{{item.zt_count}}门课</span>
						<span>{{item.zt_sumtime}}学时</span>
						<p class="dic_name" :title="item.in_dic_name.toString()">{{item.in_dic_name.toString()}}</p>
					</div>
					<div class="courseBottom">
						<p v-if="!item.is_buy" class="coursePrice">￥<span>{{item.zt_price}}</span></p>
						<p v-if="item.is_buy" class="coursePlay" @click.stop="gotoVideoPlay(item)">立即学习</p>
						<p v-else class="courseSign" @click.stop="getSpecialDe(item)">立即购买</p>
					</div>
				</div>
			</div>
			<div class="coursePaginat" v-if="total > pageSize">
				<el-pagination
				  background
				  layout="prev, pager, next"
				  :total="total"
				  :page-size="pageSize"
				  @current-change="changPage">
				</el-pagination>
			</div>
		</div>
		<div v-else class="noData">
			<img src="@/views/images/noData.png" />
			<p>暂无数据</p>
		</div>
	</div>
</template>

<script>
	
	export default {
		data() {
			return {
				isOpen:false,//是否展开更多行业
				industryList:[],//行业列表
				moreShow:false,//是否显示更多
				industryAll:true,//行业全部
				industryChose:"",//行业选中
				total:0,//总数
				page:1,//页码
				pageSize:8,//每页多少条
				specialList:[],//专题列表
			}
		},
		methods: {
			//点击展开收起
			openOrHide(){
				this.isOpen = !this.isOpen;
			},
			//跳转详情
			getSpecialDe(item){
				this.$router.push({
					path: "/specialInfo",
					query: {
						zt_id:this.PublicJs.Encrypt(item.zt_id)
					}
				});
			},
			//获取分类和行业
			getTypeAndIndustry(){
				var that = this;
				this.$http.post(this.PublicJs.publicPath + "/api/website/Zt/getIndustry", {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.industryList = response.data.data;
						this.$nextTick(() => {
							if(that.$refs.industryList.offsetHeight > 55){
								that.moreShow = true;
							}
						});
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					console.log(response)
				});
			},
			//获取专题列表
			getCourseList(){
				this.$http.post(this.PublicJs.publicPath + "/api/website/Zt/getList",{
					page:this.page,
					limit:this.pageSize,
					industry_id:this.industryChose,
					token:window.sessionStorage.getItem('token')
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.total = response.data.data.total;
						this.specialList = response.data.data.data;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//选择行业
			choseIndustry(item){
				if(typeof item == "string"){
					this.industryAll = true;
					this.industryChose = "";
				}else{
					this.industryAll = false;
					this.industryChose = item.dic_id;
				}
				this.page = 1;
				//获取课程列表
				this.getCourseList()
			},
			//分页
			changPage(val){
				this.page = val;
				//获取课程列表
				this.getCourseList()
			},
			//跳转首页
			toHome(){
				this.$router.push({
					path: "/"
				});
				Bus.$emit('busIsHome', true);//是否为省平台
				Bus.$emit('busmenuChose', "");
				window.sessionStorage.setItem('isHome',true)
				window.sessionStorage.setItem('menuChose',"")
			},
			//立即学习
			gotoVideoPlay(item){
				this.$router.push({
					path: "/videoPlay",
					query: {
						course_id:this.PublicJs.Encrypt(item.study_cou_id),
						source:this.PublicJs.Encrypt("specialList")
					}
				});
			}
		},
		created() {
			//获取行业
			this.getTypeAndIndustry()
			//获取课程列表
			this.getCourseList()
		}
	}
</script>

<style scoped="scoped">
	.specialList{
		width: 1200px;
		margin: 0 auto;
		padding-top: 36px;
		padding-bottom: 64px;
	}
	.crumbs {
	    font-size: 14px;
	}
	.cu{
		cursor: pointer;
	}
	.current {
	    vertical-align: top;
	    line-height: 1;
	}
	.el-breadcrumb {
	    display: inline-block;
	    vertical-align: top;
	}
	.optionList{
		width: 100%;
		height: auto;
		background-color: #FFF;
		box-shadow: 0px 5px 7px 0px rgba(179,179,179,0.17); 
		padding: 0 30px;
		margin-top: 29px;
	}
	.optionItem{
		height: 55px;
		line-height: 55px;
		border-bottom: 1px solid #EEEEEE;
		font-size: 14px;
		overflow: hidden;
		position: relative;
	}
	.optionItem:last-child{
		border: none;
	}
	.optionItem span{
		margin-right: 36px;
		color: #333;
		padding: 0 9px;
		display: inline-block;
		height: 26px;
		line-height: 26px;
		border-radius: 13px;
		cursor: pointer;
	}
	.optionItem span.active{
		background-color: #FF4936;
		color: #FFF;
	}
	.optionItem span.option_Tit{
		color: #999;
		padding: 0;
		margin-right: 20px;
	}
	.optionItem.industry{
		padding-right: 66px;
	}
	.moreIndustry{
		display: inline-block;
		position: absolute;
		right: 0;
		top: 0;
		cursor: pointer;
	}
	.moreIndustry i{
		margin-left: 5px;
	}
	.special_List{
		margin-top: 30px;
	}
	.special_List::after{
		content: "";
		display: block;
		clear: both;
	}
	.specialItem{
		width: 284px;
		height: 350px;
		float: left;
		margin-right: 20px;
		-moz-box-shadow:-1px -1px 6px 0px rgba(153,153,153,0.18); 
		-webkit-box-shadow:-1px -1px 6px 0px rgba(153,153,153,0.18); 
		box-shadow: -1px -1px 6px 0px rgba(153,153,153,0.18); 
		background-color: #FFF;
		position: relative;
		margin-bottom: 20px;
		border-radius:16px;
		position: relative;
		cursor: pointer;
	}
	.specialItem:nth-child(4n+4){
		margin-right: 0;
	}
	.specialbg{
		width: 284px;
		height: 128px;
		border-radius: 16px 16px 0px 0px;
	}
	.specialItem:nth-child(1) .specialbg{
		background: linear-gradient(72deg,#e5f1f4 0%, #d2dfe2 100%);
	}
	.specialItem:nth-child(2) .specialbg{
		background: linear-gradient(72deg,#e8edfd 0%, #ccd1e0 100%);
	}
	.specialItem:nth-child(3) .specialbg{
		background: linear-gradient(72deg,#eaf1e7 0%, #cfd9cb 100%);
	}
	.specialItem:nth-child(4) .specialbg{
		background: linear-gradient(72deg,#e1dcd5 0%, #cecdca 100%);
	}
	.specialItem:nth-child(5) .specialbg{
		background: linear-gradient(72deg,#e8edfe 0%, #c8cee3 100%)
	}
	.specialItem:nth-child(6) .specialbg{
		background: linear-gradient(72deg,#faf1e5 0%, #e9dfd1 100%);
	}
	.specialItem:nth-child(7) .specialbg{
		background: linear-gradient(72deg,#e1e0da 0%, #ccd5d5 100%);
	}
	.specialItem:nth-child(8) .specialbg{
		background: linear-gradient(72deg,#dfdce5 0%, #e4d3e0 100%);
	}
	.specialImg{
		width: 244px;
		height: 148px;
		background: #ffffff;
		border-radius: 10px;
		box-shadow: -1.04px 4.89px 18px 5px rgba(51,51,51,0.10);
		position: absolute;
		top: 50px;
		left: 50%;
		margin-left: -122px;
	}
	.specialTit{
		padding: 0 20px;
		font-weight: 400;
		text-align: left;
		color: #000000;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: 1;
		font-size: 18px;
		margin-top: 90px;
	}
	.specialCourses{
		margin-top: 20px;
		padding: 0 20px;
		position: relative;
	}
	.specialCourses span{
		line-height: 1;
		font-size: 14px;
		color: #f24736;
		margin-right: 10px;
	}
	.dic_name{
		position: absolute;
		right: 20px;
		width: 100px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		top: 5px;
		font-size: 14px;
		line-height: 1;
	}
	.courseBottom{
		margin-top: 15px;
		position: relative;
		padding: 0 20px;
		line-height: 36px;
	}
	.coursePrice{
		color: #ff483b;
		font-size: 14px;
	}
	.coursePrice span{
		font-size: 20px;
	}
	.courseSign{
		width: 90px;
		height: 36px;
		background: linear-gradient(72deg,#fa4a39 0%, #fe8040 100%);
		border-radius: 6px;
		font-size: 16px;
		font-weight: 400;
		color: #ffffff;
		text-align: center;
		line-height: 36px;
		position: absolute;
		right: 20px;
		top: 0;
	}
	.coursePlay{
		width: 90px;
		height: 36px;
		background: linear-gradient(0deg,rgba(77,110,242,0.90) 0%, rgba(77,110,242,0.70) 100%);
		border-radius: 6px;
		font-size: 16px;
		font-weight: 400;
		color: #ffffff;
		text-align: center;
		line-height: 36px;
		position: absolute;
		right: 20px;
		top: 0;
	}
	.coursePaginat{
		text-align: center;
		margin-top: 58px;
	}
	.noData{
		text-align: center;
		margin-top: 50px;
	}
	.noData p{
		padding: 10px 0;
		font-size: 14px;
	}
</style>

<style>
	.coursePaginat .el-pagination.is-background .btn-next, 
	.coursePaginat .el-pagination.is-background .btn-prev, 
	.coursePaginat .el-pagination.is-background .el-pager li{
		background-color: #FFF;
	}
</style>